import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Mdx from "../components/mdx";
import Button from "../components/button";
import Text from "../components/text";
import Section from "../content/section";
import Header from "../content/header";
export const pageQuery = graphql`
  query {
    contentfulImprint {
      headline
      content {
        childMdx {
          body
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Header isVisible mdxType="Header" />
    <Section mdxType="Section">
  <br />
  <br />
  <br />
  <Mdx mdxType="Mdx">{props.data.contentfulImprint.content}</Mdx>
    </Section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      